<template>
  <div v-loading="loading">
    <div v-if="!loading" class="detail">
      <div v-for="item in newDataList" :key="item.id" class="item">
        <div v-if="item.type!=='title'" class="label" :style="{width:labelWidth}">{{ item.label }}：</div>
        <!-- 标题 -->
        <div v-if="item.type==='title'" class="title">{{ item.value }}</div>
        <!-- 状态 -->
        <div v-else-if="item.type==='status'" :style="{color:item.textColor[item.value]}">{{ item.value }}</div>
        <!-- 富文本 -->
        <div v-else-if="item.type==='rich'" class="rich" v-html="$setContent(item.value)" />
        <!-- 常用文本 -->
        <div v-else>{{ item.value }}</div>
      </div>
    </div>
    <div v-if="replyData.length" class="handle">
      <div v-for="item in replyData" :key="item.id" :style="{display:item.value?'':'none'}" class="item">
        <div class="label">{{ item.label }}：</div>
        <div class="right">{{ item.value }}</div>
      </div>
    </div>
    <div v-if="!loading" class="bottomButton">
      <div class="del center click" @click="$showNotice(()=>deleteItem())">删除</div>
      <div class="back center click" @click="goBack">返回</div>
    </div>
    <div style="height:50px" />
  </div>
</template>

<script>
export default {
  props: {
    detailData: { // 详情内容
      type: Array,
      default: () => []
    },
    labelWidth: {
      type: String,
      default: '106px'
    },
    replyDataList: { // 回复内容
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      param: {},
      newDataList: [],
      replyData: [],
      loading: true
    }
  },

  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      this.param = this.$getParams()
      this.loading = true
      this.$store.dispatch(this.$parent.methodPath.getInfoFromId, { id: this.param.id }).then(res => {
        if (res.code === 0) {
          this.newDataList = this.detailData.map(item => {
            item.value = res.data[item.id] || '--'
            return item
          })
          if (res.data.replyData) { // 回复后才有
            this.replyData = this.replyDataList.map(item => {
              item.value = res.data.replyData[item.id]
              return item
            })
          } else {
            this.replyData = this.replyDataList.map(item => {
              item.value = res.data[item.id]
              return item
            })
            this.replyData = this.replyData.filter(item => item.value).length ? this.replyData : []
          }
        }
        this.loading = false
      })
    },
    deleteItem() {
      this.$store.dispatch(this.$parent.methodPath.del, { id: this.param.id }).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.param.page = 1
          this.goBack()
        }
      })
    },
    goBack() {
      this.$go(this.$route.meta.backUrl + `&page=${this.param.page || 1}`)
    }

  }
}
</script>

<style lang="scss" scoped>
  .detail{
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 40px;

    .item{
      display: flex;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: 27px;
      .title{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 13px;
      }

    }
  }
  .bottomButton{
    display: flex;
    padding-top: 30px;
    .del{
      width: 90px;
      height: 36px;
      border: 1px solid #EEEEEE;
      border-radius: 4px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .back{
      width: 90px;
      height: 36px;
      border: 1px solid var(--theme);
      border-radius: 4px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--theme);
      margin-left: 50px;
    }
  }
  .handle{
    background: #f6f8fa;
    padding: 1px 20px 28px 20px;
    .item{
      display: flex;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: 27px;
      .label{
        width: 106px;
      }
    }
  }
</style>
