<template>
  <div>
    <detail :reply-data-list="replyDataList" :detail-data="detailData" />
  </div>
</template>

<script>
import detail from '@/components/contentComponent/detail.vue'
export default {
  components: { detail },
  data() {
    return {
      detailData: [
        { id: 'title', label: '', type: 'title' },
        { id: 'contacts', label: '提交人' },
        { id: 'tel', label: '提交人电话' },
        { id: 'createTime', label: '提交时间' },
        { id: 'reportType', label: '类型' },
        { id: 'strStatus', label: '状态', textColor: { '待处理': '#FF3F3F', '已处理': '#42C07E' }, type: 'status' },
        { id: 'context', label: '详细内容', type: 'rich' }
      ],
      replyDataList: [
        { id: 'replyUser', label: '处理人' },
        { id: 'replyTime', label: '处理时间' },
        { id: 'remark', label: '处理备注' }
      ],
      methodPath: { // 接口必传
        getInfoFromId: 'personal/ReadilyGetReadilyInfo', // 根据id获取详情的接口
        del: 'personal/ReadilyDelReadily' // 删除接口
      }
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
