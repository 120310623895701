<template>
  <div>
    <detail :reply-data-list="replyDataList" :detail-data="detailData" />
  </div>
</template>

<script>
import detail from '@/components/contentComponent/detail2.vue'
export default {
  components: { detail },
  data() {
    return {
      detailData: [
        { id: 'name', type: 'title' },
        { id: 'contacts', label: '姓名' },
        { id: 'tel', label: '电话' },
        { id: 'email', label: '邮箱' },
        { id: 'company', label: '单位' },
        { id: 'address', label: '联系地址' },
        { id: 'name', label: '项目名称' },
        { id: 'strType', label: '项目类型' },
        { id: 'pbName', label: '对接部门' },
        { id: 'budget', label: '项目资金预算（元）' },
        { id: 'days', label: '项目计划周期（天）' },
        { id: 'createTime', label: '申请日期' },
        { id: 'context', label: '项目详情', type: 'rich' }
      ],
      replyDataList: [
        { id: 'strStatus', label: '状态', textColor: { '待审核': '#FF8610', '通过': '#2EBC74', '不通过': 'var(--theme)', '已取消': '#999999' }, type: 'status' },
        { id: 'chargeName', label: '对接负责人' },
        { id: 'chargeTel', label: '联系电话' },
        { id: 'approveRemark', label: '备注' }
      ],
      methodPath: { // 接口必传
        getInfoFromId: 'personal/DemandGetDemandInfo', // 根据id获取详情的接口
        del: 'personal/DemandDelDemand', // 删除接口
        cancel: 'personal/DemandUnDemand' // 取消接口
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
