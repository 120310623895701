<template>
  <div>
    <detail :reply-data-list="replyDataList" :detail-data="detailData" />
  </div>
</template>

<script>
import detail from '@/components/contentComponent/detail.vue'
export default {
  components: { detail },
  data() {
    return {
      detailData: [
        { id: 'subject', label: '', type: 'title' },
        { id: 'contacts', label: '提交人' },
        { id: 'tel', label: '提交人电话' },
        { id: 'createTime', label: '提交时间' },
        { id: 'pbName', label: '接收支部' },
        { id: 'strStatus', label: '状态', textColor: { '未回复': '#FF3F3F', '已回复': '#42C07E', '受理中': '#FF9912' }, type: 'status' },
        { id: 'context', label: '留言内容', type: 'rich' }
      ],
      replyDataList: [
        { id: 'replyContext', label: '回复内容' },
        { id: 'replyTime', label: '回复时间' }
      ],
      methodPath: { // 接口必传
        getInfoFromId: 'personal/ProposalGetProposalInfo', // 根据id获取详情的接口
        del: 'personal/ProposalDelProposal'
      }
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
