<template>
  <div v-loading="loading">
    <div class="detail">
      <div v-for="(item,index) in newDataList" :key="index" class="item">
        <div
          v-if="item.type !== 'title'"
          class="label"
          :style="{ width: labelWidth }"
        >
          {{ item.label }}
        </div>
        <!-- 标题 -->
        <div v-if="item.type === 'title'" class="title normal">
          {{ item.value }}
        </div>
        <!-- 状态 -->
        <div
          v-else-if="item.type === 'status'"
          class="normal"
          :style="{ color: item.textColor[item.value] }"
        >
          {{ item.value }}
        </div>
        <!-- 富文本 -->
        <div
          v-else-if="item.type === 'rich'"
          class="normal rich"
          v-html="$setContent(item.value)"
        />
        <!-- 常用文本 -->
        <div v-else class="normal">{{ item.value }}</div>
      </div>
    </div>
    <div class="line" />
    <div class="handle">
      <div v-for="(item,index) in replyData" :key="index" :style="{display:!item.value?'none':''}" class="item">
        <div class="label">{{ item.label }}：</div>
        <div
          class="right"
          :style="item.type ? { color: item.textColor[item.value] } : {}"
        >
          {{ item.value }}
        </div>
      </div>
    </div>

    <div v-if="!loading" class="bottomButton">
      <div :style="{display:!strStatus || statusData[routeName][strStatus].del || ''}" class="del center click" @click="$showNotice(()=>deleteItem())">删除</div>
      <div :style="{display:!strStatus || statusData[routeName][strStatus].cancel || ''}" class="back center click" @click="$showNotice(()=>cancelsMethod(),'确定取消?')">取消</div>
      <div class="back center click" @click="goBack">返回</div>
      <div :style="{display:!strStatus || statusData[routeName][strStatus].reset || ''}" class="reset center click" @click="reEdit">重新编辑提交</div>
    </div>
    <div style="height:50px" />
  </div>
</template>

<script>
import { statusData } from '@/views/personal/page/common.js'
export default {
  props: {
    detailData: {
      // 详情内容
      type: Array,
      default: () => []
    },
    labelWidth: {
      type: String,
      default: '200px'
    },
    replyDataList: {
      // 回复内容
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      param: {},
      newDataList: [],
      replyData: [],
      statusData, // 根据状态做出按钮的隐藏
      strStatus: '', // 状态
      routeName: this.$route.meta.backUrl.split('?')[0].split('/').slice(-1)[0], // 唯一标识
      responseData: '',
      loading: true
    }
  },
  computed: {
    methodPath() {
      return this.$parent.methodPath
    }
  },

  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      this.param = this.$getParams()
      this.loading = true
      this.$store
        .dispatch(this.methodPath.getInfoFromId, { id: this.param.id })
        .then((res) => {
          if (res.code === 0) {
            this.newDataList = this.detailData.map((item) => {
              item.id && (item.value = res.data[item.id] || '--')
              return item
            })
            this.replyData = this.replyDataList.map((item) => {
              item.value = res.data[item.id]
              return item
            })
            this.strStatus = res.data['strStatus'] // 当前的状态 中文
            this.responseData = res.data
            this.responseData.id = this.param.id
          }
          this.loading = false
        })
    },
    deleteItem() {
      this.$store.dispatch(this.methodPath.del, { id: this.param.id }).then(res => {
        if (res.code === 0) { // 删除成功
          this.$message.success('删除成功')
          this.param.page = 1
          this.goBack()
        }
      })
    },
    cancelsMethod() {
      this.$store.dispatch(this.methodPath.cancel, { id: this.param.id }).then(res => {
        if (res.code === 0) { // 删除成功
          this.$message.success('取消成功')
          this.param.page = 1
          this.goBack()
        }
      })
    },
    goBack() {
      this.$go(this.$route.meta.backUrl + `&page=${this.param.page || 1}`)
    },
    reEdit() {
      window.sessionStorage.setItem('responseData', JSON.stringify(this.responseData))
      this.$go('/DemandCreate?edit=1')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 40px;
  .item {
    display: flex;
    .label {
      background: #f6f8fa;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      padding: 16px 20px;
      border-top: 1px solid #e0e0e0;
      line-height: 32px;
      text-align: center;
      word-break: break-all;
    }
    .normal {
      flex: 1;
      padding: 16px 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-top: 1px solid #e0e0e0;
      line-height: 32px;
      word-break: break-all;
    }
  }
}
.bottomButton {
  display: flex;
  padding-top: 30px;
  &>div{
    margin-right: 40px;
  }
  .del {
    width: 90px;
    height: 36px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  .back {
    width: 90px;
    height: 36px;
    border: 1px solid var(--theme);
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: var(--theme);
  }
  .reset{
    width: 120px;
    height: 36px;
    background: var(--theme);
    border: 1px solid var(--theme);
    border-radius: 4px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }
}
.handle {
  background: #f6f8fa;
  padding: 1px 20px 28px 20px;
  .item {
    display: flex;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: 27px;
    .label {
      width: 106px;
    }
  }
}
.line{
  width: 1056px;
  height: 1px;
  background: #E0E0E0;
}
.title{
  text-align: center;
}
</style>
